import React from 'react'
import { MDBCol } from 'mdbreact'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

class CardIcons extends React.Component {
  render() {
    return (
      <>
        <MDBCol lg="4" md="6" className="pb-30 d-flex">
          <div className="card flex-fill outline card-hover">
            <div
              className="card-body"
            >
              <div
                style={{
                  minWidth: '85px',
                  maxWidth: '85px',
                }}
              >
                <Img
                  fixed={this.props.imageUrl}
                  className="mr-1 mb-3"
                  alt={this.props.imageAltText}
                />
              </div>

              <div>
                {this.props.link.substring(0,4) != 'http' ?
                  <Link
                    to={this.props.link}
                    className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large mt-3 effect"
                  >
                    {this.props.title}
                  </Link>
                : 
                  <a 
                    href={this.props.link}
                    className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large mt-3 effect" 
                    target="_blank"
                    rel="noopener"
                  >
                    {this.props.title}
                  </a>
                }
                {this.props.subtitle != null && (
                  <p className="font-w-400 text-medium pt-2">
                    {this.props.subtitle}
                  </p>
                )}
              </div>
            </div>
          </div>
        </MDBCol>
      </>
    )
  }
}

export default CardIcons
